<!--
  返回组件：
  默认，返回上一页，或者传prop：url返回指定路由path
-->

<template>
  <div class="bg-white flex-start container" @click="goBack">
    <div>
      <a-icon type="left-circle" theme="filled" class="gray font-xl" />
      <span class="ml-1x font-xl gray">{{ iconText }}</span>
    </div>
    <p class="font-xl gray text">{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: "Back",
  components: {},
  props: {
    iconText: {
      type: String,
      default: "返回"
    },
    text: {
      type: String,
      default: "可取门票"
    },
    url: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$emit("backEvent");
      if (this.url) {
        this.$router.push({ path: this.url });
      } else {
        this.$router.back();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.text {
  margin-left: 300px;
}
</style>
